import { combineReducers } from 'redux'

import usuario from './usuario'
import datos from "./datos";

const rootReducer = combineReducers({
	usuario: usuario,
	datos: datos,
});

export default rootReducer