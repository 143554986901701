import { fromJS } from "immutable";
import { LOG_USER, REGISTRA_USER, LOGOUT_USER, TRAE_USER, SEND_USER_MSJ, UPDATE_STAT, UPDATE_USER, SEND_CONTACTO } from "../actions";
import moment from 'moment'

const initialHeader = {
	user: null,
	perfil: {},
	direcciones: [],
	pedidos: [],
	msj: {
		tipo: 1,
		contenido: "",
	},
	contador: 0,
	contador_contacto:0,
};

const usuario = (state = initialHeader, action) => {
	const estadoNuevo = Object.assign({}, state);
	const datosNuevo = fromJS(estadoNuevo);

	switch (action.type) {
		case SEND_CONTACTO:
			const envioNew = datosNuevo.updateIn(["contador_contacto"], (value) => moment().unix());
			return envioNew.toJS();
		default:
			return state;
	}
};

export default usuario;
