import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getIni } from "../actions";
import VisionModulo from "../components/vision_modulo"
import VisionModulo2 from "../components/vision_modulo2";

class Nosotros extends Component {
	constructor(props) {
		super(props);
		this.state = {
			correo: "",
			pass: "",
		};
		this.equipoRef = React.createRef();
		this.amigosRef = React.createRef();
	}

	componentDidMount() {
		const {
			match: { params },
		} = this.props;
		params.dest ? window.scrollTo(0, this[params.dest + "Ref"].offsetTop - 80) : window.scrollTo(0, 0);
	}

	componentDidUpdate(prevProps, prevState) {}


	cambia = (key, value) => {
		this.setState({ [key]: value });
	};

	render() {
		
		return (
			<div className="container-fluid">
				{1 === 3 && <h4 className="display-4 naranja mt-4">Nosotros</h4>}
				<div className="row mt-3 p-2">
					<h5 className="mb-4 verde">
						<i className="fas fa-chevron-right"></i> Nuestro Equipo
					</h5>
					<div
						className="row mb-4"
						ref={(ref) => {
							this.equipoRef = ref;
						}}
					>
						{this.props.datos.equipo.map((eq, i) => {
							console.log(eq);
							let imgs = eq.imgs.split(",");
							var img = eq.imgs != "" ? imgs[0] : "0.jpg";

							return (
								<div className="col-6 col-sm-4 col-md-3 col-lg-2 pb-3" key={i}>
									<div className="card h-100">
										<img
											src={"https://globaltecnos.com.ar/imgs/" + eq.id + ".jpg"}
											onError
											className="card-img"
											alt=""
											onError={(e) => {
												e.target.onerror = null;
												e.target.src = "https://globaltecnos.com.ar/imgs/staff_new/default.jpg";
											}}
										/>
										<div className="card-img-overlay" style={{ padding: ".75rem", top: "auto" }}>
											{eq.correo && eq.correo != "" && (
												<a href={"mailto:" + eq.correo} className="btn btn-sm btn-outline-light mr-2 align-self-end" target="_blank">
													<i className="far fa-envelope"></i>
												</a>
											)}
											{eq.linkedin && eq.linkedin != "" && (
												<a href={eq.linkedin} className="btn btn-sm btn-outline-light mr-2 align-self-end" target="_blank">
													<i className="fab fa-linkedin-in"></i>
												</a>
											)}
											{eq.twitter && eq.twitter != "" && (
												<a href={"https://twitter.com/" + eq.twitter} className="btn btn-sm btn-outline-light mr-2 align-self-end" target="_blank">
													<i className="fab fa-twitter"></i>
												</a>
											)}
											<p className="card-text text-white mb-0 mt-2" style={{ fontSize: "13px" }}>
												{eq.titulo}
											</p>
											<h5 className="card-title mb-0  text-white mt-0">{eq.nombre}</h5>
											<h6 className="card-title text-uppercase  text-white  mb-1" style={{ fontSize: "14px", fontWeight: "700" }}>
												{eq.apellido}
											</h6>
										</div>
									</div>
								</div>
							);
						})}
					</div>

					<VisionModulo2
						titulo={"Misión"}
						contenido={this.props.datos.nosotros.length > 0 ? this.props.datos.nosotros[2].contenido : ""}
						titulo2={"Visión"}
						contenido2={this.props.datos.nosotros.length > 0 ? this.props.datos.nosotros[1].contenido : ""}
						pos={0}
					/>
					{1 === 3 && (
						<VisionModulo contenido={this.props.datos.nosotros.length > 0 ? this.props.datos.nosotros[0].contenido : ""} imgs={"./imgs/agricultura.jpg"} titulo={"Reseña"} pos={1} />
					)}

					<h5 className="mb-4 verde mt-4">
						<i className="fas fa-chevron-right"></i> Nos Acompañan
					</h5>

					<div
						className="row"
						ref={(ref) => {
							this.amigosRef = ref;
						}}
					>
						{this.props.datos.amigos.map((ami, i) => {
							return (
								<div className="col-3 col-md-2 col-lg-1 pb-3" key={"a_" + i}>
									<div className="card text-white" style={{ border: "0px" }}>
										<img src={"https://globaltecnos.com.ar/imgs/logos/" + ami.id + ".jpg"} className="card-img-top" alt="" />

										<div className="card-img-overlay bg_verde conOver p-2  text-center d-flex flex-column  justify-content-center">
											<h5 className="card-title text-capitalize mb-0 self" style={{ fontSize: "12px" }}>
												{ami.nombre}
											</h5>
											<p className="card-text text-uppercase" style={{ fontSize: "8px" }}>
												{ami.etiqueta}
											</p>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
        usuario: state.usuario,
        datos:state.datos
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getIni: () => {
			dispatch(getIni());
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Nosotros);
