import React,{useEffect} from 'react';
import Marquee from '../components/marquee'
import Modulo from "../components/home_modulo";
import ModuloVideo from "../components/home_modulo_video";

const Home = (props) => {

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
	<React.Fragment>
		<Marquee />

		<div className="row p-2">
			<Modulo imagen="./imgs/agricultura.jpg" titulo="Agricultura" dest="/servicios/agricultura"/>
			<Modulo imagen="./imgs/mercado_hoy.jpg" titulo="Mercado Hoy"  dest="/servicios/mercado"/>
			<Modulo imagen="./imgs/ganaderia.jpg" titulo="Ganadería"  dest="/servicios/ganaderia"/>
			<Modulo imagen="./imgs/administracion.jpg" titulo="Administración"  dest="/servicios/administracion"/>
			<Modulo imagen="./imgs/viajes.jpg" titulo="Viajes"  dest="/servicios/viajes"/>
			<Modulo imagen="./imgs/capital_humano.jpg" titulo="Capital Humano"  dest="/servicios/capital_humano"/>

			{1 === 3 && <ModuloVideo imagen="./imgs/unidad.jpg" titulo="Conocenos" />}
			{1 === 3 && <Modulo imagen="./imgs/politica.jpg" titulo="Politica" />}
		</div>
	</React.Fragment>
	)
}

export default Home;