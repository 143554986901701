import React from "react";
import { Link } from "react-router-dom";

const HomeModulo = (props) => (
	<div className="row mb-2">
		{props.pos % 2 == 0 && (
			<div className="col-12 col-md-6 order-1 order-md-1">
				<img src={props.datos.img} className="w-100" alt="" />
			</div>
		)}
		<div className="col-12 col-md-6 order-3 order-md-1">
			<h4 className=" verde mb-3" style={{ marginTop: "20px" }}>
				{props.datos.titulo}
			</h4>
			<div  className="mb-3" dangerouslySetInnerHTML={{ __html: props.datos.desc }}></div>
			<Link to={"/contacto/"+props.datos.dest}><b>Quiero saber más</b></Link>
		</div>
		{props.pos % 2 != 0 && (
			<div className="col-12 col-md-6 order-1 order-md-2">
				<img src={props.datos.img} className="w-100" alt="" />
			</div>
		)}
	</div>
);

export default HomeModulo;

