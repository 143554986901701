import React, { Component } from "react";
import { connect } from "react-redux";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
//autoWidth
import { getNotas } from "../actions";
class Actualidad extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
		};
	}

	componentDidMount() {
        console.log(this.props.datos.notas);
        this.props.getNotas();
	}

	componentDidUpdate(prevProps, prevState) {
		console.log(this.props.datos.notas);
	}

    createMarkup = (txt) =>{
		return { __html: txt };
    }
            
	render() {
		var notas = this.props.datos.notas.map((nota, i) => {
			var media = (nota.media)?JSON.parse(nota.media):[]
			console.log(media)
			return (
				<li key={"info_" + i} >
					<div style={{float:"none"}} className="clearfix  naranja">
						<div style={{ float: "left" }}>{nota.fecha}</div>
						<div style={{ float: "right" }}>{nota.rubro}</div>
					</div>
					<h4 className="verde">{nota.titulo}</h4>
					<div dangerouslySetInnerHTML={this.createMarkup(nota.copete)} className="mb-4"></div>
					<div className="btn-group mb-3">
					{media.filter(m=>m.tipo==3).map((me,i)=>{
						return <a  key={i} href={"https://globaltecnos.com.ar/"+me.fuente} target="_blank" className="btn btn-primary btn-sm" style={{backgroundColor:"#de731b",border:0}}><i class="fas fa-file-download"></i>  DESCARGAR</a>
					})}
					</div>
				</li>
			);
		});
		return (
            <div>
                <ul className="mercados_lista">
                    {notas}
                </ul>
            </div>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		datos: state.datos,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getNotas: () => {
			dispatch(getNotas());
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Actualidad);
