import React, { useRef } from "react";
import { Link } from "react-router-dom";
import YouTube from "react-youtube";

const VisionModulo2 = (props) => {
	const video = useRef(null);

	const onReady = (event) => {
		console.log(event.target);
		event.target.pauseVideo()
	};

	const onPlay = (event) => {
		console.log("PLAY");
	};

	const sobre = () => {
		video.current.internalPlayer.playVideo();
	};

	const sale = () => {
		video.current.internalPlayer.pauseVideo();
	};

	const opts = {
		height: "390",
		width: "640",
		playerVars: {
			// https://developers.google.com/youtube/player_parameters
			autoplay: 1,
		},
	};
	return (
		<div className="row mb-2" style={{ width: "100%" }}>
			<div className="col-12 col-md-4" onMouseEnter={sobre} onMouseOut={sale}>
				<div className="embed-responsive embed-responsive-16by9 " style={{ border: 0, height: "100%" }}>
					<YouTube ref={video} videoId="2JLIQZGP9zQ" opts={opts} onReady={onReady} onPlay={onPlay} className="embed-responsive-item" />;
				</div>
			</div>
			{1 === 3 && (
				<div className="col-12 col-md-8">
					<h3 className=" verde mb-3" style={{ marginTop: "20px" }}>
						{props.titulo}
					</h3>
					<div dangerouslySetInnerHTML={{ __html: props.contenido }}></div>
					<h3 className=" verde mb-3" style={{ marginTop: "20px" }}>
						{props.titulo2}
					</h3>
					<div dangerouslySetInnerHTML={{ __html: props.contenido2 }}></div>
				</div>
			)}
			<div className="col-12 col-md-8">
				
				<div dangerouslySetInnerHTML={{ __html: 'Nos formamos en el movimiento <b>CREA</b>, con más de 15 años de trabajo dentro de la institución, y con una fuerte vinculación con la misma. A comienzos del año 2012, constituimos una <b>consultora</b> independiente generando una sinergia muy particular con el movimiento CREA.<br><br>Nuestro foco está puesto en lo <b>comercial-empresarial</b>, a partir del conocimiento de los <b>mercados y la comercialización agrícola.</b> Somos un equipo multidisciplinario, proactivo y que busca la excelencia, referentes en la generación de conocimiento e innovación en la comunidad agroalimentaria.' }}></div>
			</div>
			
		</div>
	);
};
export default VisionModulo2;
