import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { getMercados } from "../actions";
import MercadoFila from "../components/mercado_fila"

class Mercados extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
		};
	}

	async componentDidMount() {
		this.props.getMercados();
		console.log("EN Mercados");
		//console.log(this.props.datos.mercado)
	}

	componentDidUpdate(prevProps, prevState) {
		//console.log(this.props.datos.mercado)
		console.log("UPDATE MERCADO");
		//console.log(this.props.datos.ultimaactmerc+180)
		//console.log(moment().unix())
		if (this.props.datos.ultimaactmerc + 180 < moment().unix()) {
			console.log("VA A BUSCAR MERCADOS");
			this.props.getMercados();
		}
	}

	componentWillUnmount() {
		console.log("UNMOUNT MERCADO");
		//console.log(this.props.datos.mercado)
	}

	pieLista = (section) => {
		return (
			<div >
				<div styles={{ height: 80 }}>
					<img src="http://globaltecnos.com.ar/imgs_web/crea.jpg"  />
				</div>
				<div styles={{ height: 80 }}>
					<img src="http://globaltecnos.com.ar/imgs_web/santander_sp.jpg"  />
				</div>
			</div>
		);
	};

	renderHeader = (section) => {
		return (
			<div className="merdados_header">
				<div>{section.section.title}</div>
				<div>{moment(section.section.fecha).format("DD.MM.YY")}</div>
			</div>
		);
	};

	row = (data) => {
		console.log(data);
		var icono = data.item.signo == "=" ? <i className="equals"></i> : data.item.signo == "+" ? <i className="angleup"></i> : <i className="angledown"></i>;
            
           /*{data.item.diferencia} {icono}*/
		var color = data.item.signo == "=" ? "#999999" : data.item.signo == "+" ? "#009B7A" : "#db1f2a";
		if (data.item.label.toUpperCase() == "TOTALES") {
			return (
				<div>
					<div>
						<div>{data.item.label.toUpperCase()}</div>
						<div>{data.item.monto}</div>
						<div></div>
					</div>
				</div>
			);
		} else {
			return (
				<div>
					<div>
						<div>{data.item.label.toUpperCase()}</div>
						<div>{data.item.monto}</div>
						<div>
							{data.item.diferencia} {icono}
						</div>
					</div>
				</div>
			);
		}
	};

	render() {
        const mercadoDisplay = this.props.datos.mercados.map((m,i)=>{
            const filas = m.data.map((fila,a)=>{
                return <MercadoFila datos={fila} key={"mercado_"+i+"_"+a} />
            })

			return (
				<li key={"mercaod_" + i} className="mercados_fila">
					<h3 className="verde">{m.title}</h3>
					<table style={{fontSize:"12px",width:"100%"}}>
						<tbody>
							{filas}
						</tbody>
					</table>
				</li>
			);
        })

		return (
			<div>
				{this.state.isLoading === false ? (
					<React.Fragment>
						<h5 className="informe_mod_tit">
							<i className="fa fa-chevron-down pull-right" aria-hidden="true"></i> INFORME DEL: {this.props.datos.mercado_fecha}
						</h5>
						<ul className="mercados_lista">{mercadoDisplay}</ul>
					</React.Fragment>
				) : (
					<React.Fragment>
						<div>CARGANDO</div>
					</React.Fragment>
				)}
			</div>
		);
	}
}


const mapStateToProps = (state, ownProps) => {
	return {
		datos: state.datos,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getMercados: () => {
			dispatch(getMercados());
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Mercados);
