import React, { Component } from "react";
import { connect } from "react-redux";
import FormContacto from "../containers/form_contacto";
import ModYowtube from "../containers/mod_youtube";
import ModTwetts from "../containers/mod_tweets";

class Contacto extends Component {
	constructor(props) {
		super(props);
		this.state = {
			destino:""
		};
		//authRef.onAuthStateChanged(this.chkLog);
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	componentDidUpdate(prevProps, prevState) {}

	render() {
		return (
			<div className="container-fluid">
				<h4 className="display-4 naranja mt-4">Contacto</h4>
				<div className="row mt-3">
					<div className="col-md-8">
						<div className="site-section bg-light">
							<div className="container">
								<div className="row pt-3 pb-5 wow fadeIn animated" style={{ visibility: "visible" }}>
									<div className="col-sm-5 pb-3">
										<p className="h5">Nos interesan tus comentarios!</p>
										<p>Si tenes alguna consulta no dudes en enviarnos un mensaje.</p>

										<hr className="teal accent-3 mb-4 mt-0 d-inline-block mx-auto" style={{ width: "60px" }} />
										<p style={{ marginBottom: "0px" }}>
											<i className="fas fa-home mr-3"></i> Uruguay 911 4to A
										</p>
										<p style={{ fontSize: "13px", marginLeft: "40px" }}>CP C1015 | BUENOS AIRES | ARG</p>
										<p>
											<i className="fas fa-envelope mr-3"></i> info@globaltecnos.com.ar
										</p>
										{1 == 3 && (
											<p>
												<i className="fas fa-phone mr-3"></i> +54 11 47 74 04 13
											</p>
										)}
									</div>
									<FormContacto dest={this.props.match.params} />
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-4">
						{1 == 3 && <ModYowtube />}
						{1 == 3 && <ModTwetts />}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		usuario: state.usuario,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		/*getIni: () => {
			dispatch(getIni());
		}*/
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Contacto);
