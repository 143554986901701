import React,{Component} from "react";
import { connect } from "react-redux";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { getActualidad } from "../actions";
//autoWidth


class Infografia extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
		};
	}

	async componentDidMount() {
		console.log(this.props.datos.infografia)
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.datos.infografia.media.length<1){
			this.props.getActualidad()
		} 
    }


	render() {
        var info = this.props.datos.infografia.media.map((info,i)=>{
            return (
				<div key={"info_" + i} className="slide item ">
					<img src={"http://globaltecnos.com.ar/" + info.fuente } width="100%"/>
				</div>
			);
        })
		return (
			<div style={{ position: "relative", paddingBottom: "50px" }}>
				<OwlCarousel
					className="owl-theme owl-carousel home wow fadeIn "
					loop
					items={1}
					margin={25}
					center={true}
					autoplay={true}
					autoplaySpeed={1500}
					smartSpeed={6000}
					nav={false}
				>
					{info}
				</OwlCarousel>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		datos: state.datos,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getActualidad: () => {
			dispatch(getActualidad());
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Infografia);
