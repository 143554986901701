import React, { Component } from "react";
import { BrowserRouter as Router, Route, Link, Redirect, withRouter } from "react-router-dom";

import { authRef } from "../config/firebase";
import { connect } from "react-redux";
import { getIni } from "../actions";

import Header from '../containers/header'
import Footer from '../components/footer'
import Home from "../components/home";
import Nosotros from "../containers/nosotros";
import Mercados from "../containers/mercados";
import Servicios from "../containers/servicios";
import Contacto from "../containers/contacto";

class App extends Component {
  
	constructor(props) {
		super(props);
		this.state = {};
		//authRef.onAuthStateChanged(this.chkLog);
	}

	componentDidMount() {
		this.props.getIni()
	}

	componentDidUpdate(prevProps, prevState) {}

	render() {
		return (
			<React.Fragment>
				<Header history={this.props.history} />
				<div>
					<Route path="/" exact component={Home} />
					<Route path="/nosotros/:dest?" exact component={Nosotros} />
					<Route path="/servicios/:dest?" exact component={Servicios} />
					<Route path="/mercados" exact component={Mercados} />
					<Route path="/contacto/:dest?" exact component={Contacto} />
				</div>
				<Footer />
			</React.Fragment>
		);
	}
}

const PrivateRoute = ({ u,component: Component, ...rest }) => (
  <Route
    {...rest}
    render={ props =>
      u   ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

const mapStateToProps = state => {
  return {
    usuario: state.usuario,
    }
}

const mapDispatchToProps = dispatch => {
  	return {
		getIni: () => {
			dispatch(getIni());
		}
  	};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App)
