import React from "react";
import YouTube from "react-youtube";

class HomeModuloVideo extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
		};
		this.you = {};
	}

	componentDidMount() {}

	componentDidUpdate(prevProps, prevState) {}

	_onReady(event) {
		console.log(event.target);
		event.target.pauseVideo()

	}

	_onPlay(event) {
		console.log("PLAY");
	}

	sobre = () => {
		this.you.internalPlayer.playVideo();
	};

	sale = () => {
		this.you.internalPlayer.pauseVideo();
	};

	render() {
		const opts = {
			height: "390",
			width: "640",
			playerVars: {
				// https://developers.google.com/youtube/player_parameters
				autoplay: 1,
			},
		};

		return (
			<div className="col-12 col-sm-2 col-md-6 col-lg-4 mb-2">
				<div className="card home_modulo" onMouseEnter={this.sobre} onMouseOut={this.sale}>
					<div className="embed-responsive embed-responsive-16by9 " style={{ border: 0, height: "100%" }}>
						<YouTube
							ref={(ref) => {
								this.you = ref;
							}}
							//ref={this.you}
							videoId="2JLIQZGP9zQ"
							opts={opts}
							onReady={this._onReady}
							onPlay={this._onPlay}
							className="embed-responsive-item"
						/>
						;
					</div>

					<div className="card-img-overlay text-white overlay_video">
						<img src={this.props.imagen} className="card-img" alt="" />
						<h3 className="text-center  txt_home_modulo" style={{ marginTop: "28%", textShadow: "2px 2px 4px #333" }}>
							{this.props.titulo}
						</h3>
					</div>
				</div>
			</div>
		);
	}
}

export default HomeModuloVideo;

