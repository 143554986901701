import React, { Component } from "react";
import { connect } from "react-redux";
import { getVideos } from "../actions";
import YouTubeViewer from '../components/mod_youtube_viewer'

class ModYoutube extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      select: null,
      videos: []
    };
  }

  componentDidMount() {
    this.props.getVideos()
  }

  componentDidUpdate(prevProps, prevState) {
    console.log(this.state.videos);
    if(prevProps.data.videos!=this.props.data.videos){
      this.setState({
        select: this.props.data.videos[0],
        videos: this.props.data.videos
      });
    }
  }

  cambiaVideo = (v)=>{
    this.setState({select:v})
  }
  
  render() {

    const videos =(this.state.videos.length<1)? "" : this.state.videos.map((v,i)=>{
      console.log(v)
        return <a className="col-3" onClick={ ()=> this.cambiaVideo(v)} key={"v_"+i}>
            <img src={"https://img.youtube.com/vi/"+v.id+"/sddefault.jpg"} alt={v.fecha} className="img-thumbnail" key={i} />
        </a>
    })
    return (
      <div className="mb-2">
        {this.state.select &&
          <YouTubeViewer video={this.state.select} />
        }
        <div className="row">{videos}</div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    data: state.datos
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getVideos: () => {
      dispatch(getVideos());
    }
  };
};

export default  connect(
  mapStateToProps,
  mapDispatchToProps
)(ModYoutube)
