import React, { Component } from "react";
import { connect } from "react-redux";
import MercadosMod from "../containers/mercados_mod";
import InfografiaMod from "../containers/mod_infografia";
import ActualidadMod from "../containers/actualidad_mod";

class Mercados extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		//authRef.onAuthStateChanged(this.chkLog);
	}

	componentDidMount() {
		console.log("EN MERCADOS")
		window.scrollTo(0, 0);
    }

	componentDidUpdate(prevProps, prevState) {}

	render() {
		return (
			<div className="container-fluid">
				<div className="row mt-3">
					<div className="col-md-8 order-2 order-md-1">
						<ActualidadMod />
					</div>
					<div className="col-md-4 order-1 order-md-2">
						<InfografiaMod />
						<MercadosMod />
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		usuario: state.usuario,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		/*getIni: () => {
			dispatch(getIni());
		}*/
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Mercados);
