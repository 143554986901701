import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/auth';
import "firebase/database";

import { FirebaseConfig } from "../config/keys";
firebase.initializeApp(FirebaseConfig);
//const settings = {timestampsInSnapshots: true};
//const databaseRef = firebase.database().ref();
const settings = {  };
export const dbFirestore = firebase.firestore();
dbFirestore.settings(settings);

export const usuarioRef = dbFirestore.collection("usuarios");
export const sesionesRef = dbFirestore.collection("sesiones");
export const usuarios_online = firebase.database().ref("/usuarios_online");
export const refStorageImg = firebase.storage().ref('/imgs');
export const authRef = firebase.auth();
export const deposito = firebase.storage()
