import React from "react";
import { NavLink, Link, Redirect } from "react-router-dom";

const HomeModulo = (props) => (
	<Link to={props.dest} className="col-12 col-sm-2 col-md-6 col-lg-4 mb-2">
		<div className="card home_modulo">
			{props.titulo === "Conocenos" ? (
				<div class="embed-responsive embed-responsive-16by9" style={{ border: 0, height: "100%" }}>
					<iframe class="embed-responsive-item" src="https://www.youtube.com/embed/brQVFbK3HJc?rel=0" allowfullscreen style={{ height: "100%" }}></iframe>
				</div>
			) : (
				<React.Fragment>
					<img src={props.imagen} className="card-img" alt="" />
					<div className="card-img-overlay text-white">
						<h3 className="text-center  txt_home_modulo" style={{ marginTop: "28%", textShadow: "2px 2px 4px #333" }}>
							{props.titulo}
						</h3>
					</div>
				</React.Fragment>
			)}
		</div>
	</Link>
);

export default HomeModulo;

