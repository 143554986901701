import React from "react";


const MercadoFila = (props) => {
	var icono = props.datos.signo == "=" ? <i className="fas fa-equals"></i> : props.datos.signo == "+" ? <i className="fas fa-angle-up"></i> : <i className="fas fa-angle-down"></i>;
    var color = props.datos.signo == "=" ? "#999999" : props.datos.signo == "+" ? "#009B7A" : "#db1f2a";

	if (props.datos.label.toUpperCase() == "TOTALES") {
		return (
			<tr>
				<td style={{ width: "60%",paddingBottom:"10px" }} >{props.datos.label.toUpperCase()}</td>
				<td style={{ width: "20%" }}>{props.datos.monto}</td>
				<td style={{ width: "20%" }}></td>
			</tr>
		);
	} else {
		return (
			<tr>
				<td style={{ width: "60%", paddingBottom: "10px" }}>{props.datos.label.toUpperCase()}</td>
				<td style={{ width: "20%" }}>{props.datos.monto}</td>
				<td style={{ width: "20%", textAlign: "right" }} style={{ color: color }}>
					{props.datos.diferencia} {icono}
				</td>
			</tr>
		);
	}
}

export default MercadoFila;
