import React, { Component } from "react";
import { connect } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import { sendContacto } from "../actions";

class ContactoForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			enviando: false,
			dest: "",
			dest_combo: "",
			nombre: "",
			correo: "",
			asunto: "",
			msj: "",
			error: "",
			key: "6LeVjVoaAAAAABl5kpJjpUv1QToU-eY8olhvgCbk",
			mails: [
				"glegnoverde@globaltecnos.com.ar",
				"sgavalda@globaltecnos.com.ar",
				"egilbelloni@globaltecnos.com.ar",
				"geleicegui@globaltecnos.com.ar",
				"info@globaltecnos.com.ar",
				"sgavalda@globaltecnos.com.ar",
				"info@globaltecnos.com.ar",
			],
		};
		this.captchaRef = React.createRef();
	}

	componentDidMount() {
		console.log(this.props.dest);
		if (this.props.dest.hasOwnProperty("dest") && this.props.dest.dest!="") this.setState({ dest: this.state.mails[this.props.dest.dest], dest_combo: this.props.dest.dest });
	}

	componentDidUpdate(prevProps, prevState) {
		console.log(this.state)
		if (this.props.usuario.contador_contacto != prevProps.usuario.contador_contacto) {
			this.setState({
				enviando: false,
				nombre: "",
				correo: "",
				asunto: "",
				mensaje: "",
			});
		}
	}

	onChange = (value) => {
		console.log("Captcha value:", value);
	};

	onChangeDest = (value) => {
		this.setState({ dest: this.state.mail[parseInt(value)], dest_combo: value });
	};

	enviaFormContacto = () => {
		if (this.state.dest == "") {
			this.setState({
				error: "Debe elegir un destino",
			});
		} else if (this.state.nombre == "") {
			this.setState({
				error: "Debe completar su Nombre",
			});
		} else if (this.state.correo == "") {
			this.setState({
				error: "Debe completar la direccion de correo",
			});
		} else if (this.validateEmail(this.state.correo) === false) {
			this.setState({
				error: "La direccion de mail es incorrecta",
			});
		} else if (this.state.asunto == "") {
			this.setState({
				error: "Debe completar el asunto",
			});
		} else if (this.state.mensaje == "") {
			this.setState({
				error: "Debe completar el mensaje",
			});
		} else {
			const recaptchaValue = this.captchaRef.current.getValue();
			var sendData = {
				dest: this.state.dest,
				nombre: this.state.nombre,
				correo: this.state.correo,
				asunto: this.state.asunto,
				mensaje: this.state.mensaje,
				recaptcha: recaptchaValue,
			};
			/*
			const sendData = new FormData();
			sendData.append("dest", this.state.dest);
			sendData.append("nombre", this.state.nombre);
			sendData.append("correo", this.state.correo);
			sendData.append("asunto", this.state.asunto);
			sendData.append("mensaje", this.state.mensaje);
			sendData.append("recaptcha", recaptchaValue);
*/
			this.setState(
				{
					error: "",
					enviando: true,
				},
				() => this.props.sendContacto(sendData)
			);
		}
	};
	cambia = (key, value) => {
		this.setState({ [key]: value });
	};

	validateEmail(email) {
		var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	render() {
		return (
			<div className="col-sm-6 offset-sm-1">
				{this.state.error != "" && <h4>{this.state.error}</h4>}
				{this.state.enviando ? (
					<h3>ENVIANDO</h3>
				) : (
					<form>
						<div className="row">
							<div className="col-12 col-md-6 form-group">
								<select
									required="required"
									className="form-control"
									onChange={(v) => this.onChangeDest(v.target.value)}
									value={this.state.dest_combo}
									aria-describedby=""
									placeholder="Destino"
								>
									<option value="">Seleccione el destino</option>
									<option value="0">Administración</option>
									<option value="1">Agricultura</option>
									<option value="2">Capital Humano </option>
									<option value="3">Ganadería</option>
									<option value="4">Mercados hoy</option>
									<option value="5">Viajes</option>
									<option value="6">Otras consultas</option>
								</select>
							</div>
							<div className="col-12 col-md-6  form-group">
								<input
									required="required"
									type="text"
									className="form-control"
									onChange={(v) => this.cambia("nombre", v.target.value)}
									value={this.state.nombre}
									aria-describedby=""
									placeholder="Nombre"
								/>
							</div>
						</div>
						<div className="form-group">
							<input
								required="required"
								type="email"
								className="form-control"
								onChange={(v) => this.cambia("correo", v.target.value)}
								value={this.state.correo}
								aria-describedby=""
								placeholder="Email"
							/>
						</div>
						<div className="form-group">
							<input type="text" className="form-control" onChange={(v) => this.cambia("asunto", v.target.value)} value={this.state.asunto} aria-describedby="" placeholder="Asunto" />
						</div>
						<div className="form-group">
							<textarea className="form-control" onChange={(v) => this.cambia("mensaje", v.target.value)} rows="3" id="mensaje" placeholder="Mensaje" value={this.state.mensaje} />
						</div>
						<div className="row">
							<div className="col-12 col-md-8">
								<ReCAPTCHA ref={this.captchaRef} sitekey={this.state.key} onChange={this.onChange} />
							</div>
							<div className="col-12 col-md-4 text-right">
								<button type="button" className="btn btn-lg btn-success" id="btn-contacto-enviar" onClick={() => this.enviaFormContacto()}>
									Enviar
								</button>
							</div>
						</div>
					</form>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		usuario: state.usuario,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		sendContacto: (data) => {
			dispatch(sendContacto(data));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactoForm);
