import React, { Component } from "react";
import { connect } from "react-redux";
import ServiciosMod from "../components/servicio_modulo"

class Servicios extends Component {
	constructor(props) {
		super(props);
		this.state = {
			servicios: [
				{
					titulo: "Agricultura",
					desc: `<p>Esta área está abocada al análisis de los mercados agrícolas, ofreciendo una síntesis de las herramientas disponibles para obtener mejores resultados económicos y minimizar los riesgos. Así a lo largo de los años se fueron desarrollando los siguientes eventos:</p><p><b>Grupos de comercialización</b>, donde nos compartimos información de mercado y promovemos el intercambio entre los participantes, con el cliente como protagonista. </p><p><b>Capacitaciones en mercados de futuros y opciones</b> para profundizar los conocimientos sobre las diferentes herramientas que se ofrecen en la comercialización de granos. </p><p><b>Asesoramiento particular</b>, donde destaca el seguimiento de posiciones comerciales y participación en las decisiones de venta. <b>Consultorías</b> de diversas temáticas, adaptadas a las necesidades de cada cliente.</p>`,
					img: "https://globaltecnos.com.ar/imgs/agricultura.jpg",
					ref: "agriculturaRef",
					dest: "1",
				},
				{
					titulo: "Mercado Hoy",
					desc:
						"<p>Es la base de información para nuestros análisis y la interpretación de los mercados agrícolas y ganaderos. Actualizamos <b>periódicamente</b> las principales cotizaciones de interés para el sector agropecuario. </p><p>Seguimos de cerca los movimientos del mercado local e internacional, intercambio comercial, campañas de siembra y cosecha, estado de los cultivos en nuestro país y el mundo, el vaivén de los fondos especulativos y los mercados financieros, entre otras cosas. </p><p>Diariamente realizamos <b>informes comerciales</b> y redactamos notas con las novedades.</p>",
					img: "https://globaltecnos.com.ar/imgs/mercado_hoy.jpg",
					ref: "mercadoRef",
					dest: "4",
				},
				{
					titulo: "Ganadería",
					desc:
						"<p>Reconocemos el rol protagónico y creciente que ocupa la ganadería en nuestro país y el mundo. Entendemos las necesidades e inquietudes de todos los actores de la cadena y trabajamos para acompañarlos en la toma de decisiones productivas, financieras y comerciales.</p><p> Confeccionamos <b>Reportes</b> periódicos con el seguimiento de las principales variables e indicadores de los mercados de ganadería bovina, porcina y lechera, entre otros.</p><p> Llevamos adelante <b>Talleres</b> mensuales con productores, frigoríficos y otros actores de la cadena cárnica, en dónde facilitamos el intercambio y el enriquecimiento mutuo entre los participantes, al tiempo que brindamos soporte analítico y presentamos información de interés para el sector.</p><p> Ofrecemos <b>charlas y seminarios</b> de actualidad y coyuntura a pedido del cliente.</p>",
					img: "https://globaltecnos.com.ar/imgs/ganaderia.jpg",
					ref: "ganaderiaRef",
					dest: "3",
				},
				{
					titulo: "Administración",
					desc:
						"El área administrativa de las empresas agropecuarias es de las menos tenidas en cuenta al momento de capacitarse y reunirse con pares. Sin embargo es una de las áreas de las empresas que más han sentido la presión de los <b>organismos de control y necesidad de mantenerse actualizados</b> y alertas. Es por esto que desde Globaltecnos desarrollamos:<br><br><b>“Talleres de Administrativos”</b> buscando fomentar el intercambio y generar un espacio donde los equipos puedan resolver dudas e inquietudes.<br><br><b>“Capacitaciones en liquidaciones de granos y en el control del proceso comercial”</b> entendemos la importancia y el impacto del conocimiento en detalle de estos conceptos en el circuitoadministrativo, económico y financiero de las empresas.<br><br><b>“Asesoramiento administrativo y consultorías”</b> basado en las necesidades que se plantean y en la mejora continua de las áreas de administración.",
					img: "https://globaltecnos.com.ar/imgs/administracion.jpg",
					ref: "administracionRef",
					dest: "0",
				},
				{
					titulo: "Viajes",
					desc:
						"<p>Como parte del servicio integral que nos proponemos dar a nuestros clientes, <b>organizamos viajes y recorridas</b> por países y zonas <b>vinculadas al sector agro</b> para conocer sus metodologías, procesos y mentalidades.</p><p>Consideramos importante que las empresas y personas le dediquen el tiempo necesario a pensar en el largo plazo y a estar a la vanguardia de las <b>nuevas tecnologías y conocimientos</b>. </p><p>Visitamos países como; Israel, capital mundial de la innovación tecnológica aplicada al agro; Canadá, con una fuerte matriz productiva; zonas como Vaca Muerta, para ver de cerca el desarrollo que está teniendo lugar en el sur de nuestro país; puertos; acopios; y empresas del sector con la finalidad <b>de crecer personal y profesionalmente</b>.  </p>",
					img: "https://globaltecnos.com.ar/imgs/viajes.jpg",
					ref: "viajesRef",
					dest: "5",
				},
				{
					titulo: "Capital Humano",
					desc:
						"El <b>área de capital humano</b>, busca poner en agenda temas que en general tienden a ser importantes pero no tan urgentes. Es por eso que los vamos descuidando sin darnos cuenta que son clave para el crecimiento y buen funcionamiento de las organizaciones. Por eso, acompañamos desde el asesoramiento a empresas en diagnosticar y llevar a cabo un plan de trabajo en estos temas.<br><br>Además contamos con un <b>Taller de capital humano</b>, donde Gerentes del área comparten dificultades y trabajan en generar soluciones que permitan agilizar los cambios en sus organizaciones.<br><br>Además, hace ya casi 10 años que tenemos la <b>primera encuesta salarial del agro</b>, llevada a una metodología que permite comparar todo tipo de organización, no importa la escala o estructura. Empresas Líderes del Sector tienen un indicador fundamental para poder tomar mejores decisiones respecto a las políticas de aumentos salariales.",

					img: "https://globaltecnos.com.ar/imgs/capital_humano.jpg",
					ref: "capital_humanoRef",
					dest: "2",
				},
			],
		};

		this.agriculturaRef = React.createRef();
		this.mercadoRef = React.createRef();
		this.ganaderiaRef = React.createRef();
		this.administracionRef = React.createRef();
		this.viajesRef = React.createRef();
		this.capitalRef = React.createRef();

	}

	componentDidMount() {
		const { match: { params } } = this.props;
		(params.dest) ? window.scrollTo(0, this[params.dest + "Ref"].offsetTop-80):window.scrollTo(0,0)
    }

	componentDidUpdate(prevProps, prevState) {
		
	}

	render() {

		return (
			<div className="container-fluid">
				{1 === 3 && <h4 className="display-4 naranja mt-4">Servicios</h4>}
				{this.state.servicios.map((serv, i) => {
					return (
						<div key={i} ref={(ref) => {
						this[serv.ref] = ref;
						
					}}>
						<ServiciosMod
							datos={serv}
							pos={i}
						/>
						</div>
					);
				})}
			</div>
		);
	}
}

const mapStateToProps = state => {
  return {
    usuario: state.usuario,
    }
}

const mapDispatchToProps = dispatch => {
  	return {
		
  	};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Servicios)
