import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
//autoWidth

const ModMarquee = (props) => (
	<div style={{ position: "relative" }}>
		<OwlCarousel className="owl-theme owl-carousel home wow fadeIn nopadding" loop items={1} margin={0} center={true} autoplay={true} autoplaySpeed={1500} smartSpeed={6000} nav={false}>
			<div className="slide item slide03">
				<div className="container">
					<div className="row">
						<div className="col-10 offset-1 col-sm-6">
							<h2 className="h1 uppercase white shade"></h2>
						</div>
					</div>
				</div>
			</div>
			
			<div className="slide item slide00">
				<div className="container">
					<div className="row">
						<div className="col-10 offset-1 col-sm-6">
							<h2 className="h1 uppercase white shade"></h2>
						</div>
					</div>
				</div>
			</div>

			<div className="slide item slide01">
				<div className="container">
					<div className="row">
						<div className="col-10 offset-1 col-sm-6">
							<h2 className="h1 uppercase white shade"></h2>
						</div>
					</div>
				</div>
			</div>

			<div className="slide item slide02">
				<div className="container">
					<div className="row">
						<div className="col-10 offset-1 col-sm-6">
							<h2 className="h1 uppercase white shade"></h2>
						</div>
					</div>
				</div>
			</div>
			<div className="slide item slide04">
				<div className="container">
					<div className="row">
						<div className="col-10 offset-1 col-sm-6">
							<h2 className="h1 uppercase white shade"></h2>
						</div>
					</div>
				</div>
			</div>
		</OwlCarousel>
	</div>
);

export default ModMarquee;
