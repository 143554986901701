import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import reduxPromises from 'redux-promise'

import App from './components/App'
import reducers from './reducers'

const createStoreWithMiddleware = applyMiddleware(reduxPromises)(createStore);
/*basename={"/prode"}*/
ReactDOM.render(
    <Provider store={createStoreWithMiddleware(reducers)}>
        <Router>
            <Route path="/" component={App} />
        </Router>

    </Provider>
    , document.getElementById('root'));
