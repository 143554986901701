import { fromJS } from 'immutable';
import moment from 'moment'
import { GET_INI,GET_VIDEOS,GET_TWEETS ,GET_ACTUALIDAD,GET_MERCADOS,GET_NOTAS} from "../actions";

const initialTodos = {
	notas: [],
	mercados: [],
	amigos: [],
	nosotros: [],
	equipo: [],
	videos: [],
	tweets: [],
	actualidad: [],
	notas: [],
	infografia: { media: [] },
	mercado: [],
	mercado_fecha: "",
	mis_posiciones: [],
	mis_posiciones_ids: [],
	mis_posiciones_filtradas: [],
	posiciones: [],
	mercado: [],
	home: [],
	videos: [],
	video: null,
};

const datos = (state=initialTodos, action) => {
    var newState = Object.assign({}, state);
    const datos = fromJS(newState)

    switch (action.type) {
		case GET_INI:
			console.log(action.payload.data.data);
			const equipoNew = datos.updateIn(["equipo"], (value) => action.payload.data.data.equipo[0]);
			const amigosNew = equipoNew.updateIn(["amigos"], (value) => action.payload.data.data.amigos[0]);
			const nosotrosNew = amigosNew.updateIn(["nosotros"], (value) => action.payload.data.data.nosotros[0]);

			console.log(action.actualidad);
			var video = action.actualidad.home.find((element) => {
				return element.tipo == 5;
			});
			var infografia = action.actualidad.infografia[0];
			infografia.media = JSON.parse(action.actualidad.infografia[0].media);
			const actualidadNew = nosotrosNew.updateIn(["actualidad"], (value) => action.actualidad.actualidad);
			const videoNew = actualidadNew.updateIn(["video"], (value) => video);
			const homeNew = videoNew.updateIn(["home"], (value) => action.actualidad.home);
			const infografNew = homeNew.updateIn(["infografia"], (value) => infografia);

			return infografNew.toJS();

		case GET_VIDEOS:
			const videosLista = action.payload.items.map((v) => {
				return {
					id: v.id.videoId,
					titulo: v.snippet.title,
					fecha: v.snippet.publishedAt,
				};
			});
			const videosNew = datos.updateIn(["videos"], (value) => videosLista);
			return videosNew.toJS();

		case GET_TWEETS:
			const tweetsNew = datos.updateIn(["tweets"], (value) => action.payload.data);
			return tweetsNew.toJS();

		case GET_ACTUALIDAD:
			var video = action.payload.data.home.find((element) => {
				return element.tipo == 5;
			});
			var infografia = action.payload.data.infografia[0];
			infografia.media = JSON.parse(action.payload.data.infografia[0].media);
			const actualidadNew2 = datos.updateIn(["actualidad"], (value) => action.payload.data.actualidad);
			const videoNew2= actualidadNew2.updateIn(["video"], (value) => video);
			const homeNew2 = videoNew2.updateIn(["home"], (value) => action.payload.data.home);
			const infografNew2 = homeNew2.updateIn(["infografia"], (value) => infografia);
			return infografNew2.toJS();


		case GET_NOTAS:
			const notasNew = datos.updateIn(["notas"], (value) => action.payload.notas);
			return notasNew.toJS();

		case GET_MERCADOS:
			console.log(action.payload.data);
			const ultAct = datos.updateIn(["ultimaactmerc"], (value) => moment().unix());
			const mercadosNew = ultAct.updateIn(["mercados"], (value) => action.payload.data.data);
			const mercado2New = mercadosNew.updateIn(["mercado_fecha"], (value) => action.payload.data.fecha);
			return mercado2New.toJS();

		default:
			return state;
	}
}

export default datos